.simple-site-footer {
    background: #222;
    padding: 80px 0 0 0;
    text-align: center;
    &-logo {
        img {
            width: 250px;
        }
    }
    &-nav {
        padding: 10px 0;
        ul {
            list-style-type: none;
            padding: 0;
            li {
                display: inline-block;
                padding: 0 20px;
                @media (max-width: 1200px){
                    display: block;
                    padding: 10px;
                }
                a {
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 800;
                    text-decoration: none;
                    padding-bottom: 5px;
                }
            }
        }
    }
    &-social {
        padding: 10px 0 60px 0;
        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
                padding: 0 20px;
                
                a {
                    background: #1a1a1a;
                    padding: 20px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100px;
                    img {
                        width: 28px;
                        height: auto;
                    }
                }
            }
        }
    }
    &-siblings {
        padding: 50px 0 40px;
        background: #101010;
        h4 {
            color: $brand-color;
            text-transform: uppercase;
            margin-bottom: 20px;
            font-size: 14px;
        }
        
    }
}

.bottom-bar {
    padding: 15px 0;
    width: 100%;
    background: #111;
    text-align: center;
    overflow: hidden;
    a {
        color: #ddd;
        text-decoration: none;
    }
    &-container {
        display: flex;
        justify-content: center;
        max-width: 1400px;
        margin: 0 auto;
        align-items: center;
        @media (max-width: 1200px){
            flex-direction: column;
            width :100%;
        }
        
    }
   
    &-disclaimer {
        padding: 0 20px;
        line-height: 1.4;
        font-size: 13px;
        color: #ddd;
        width: 100%;
        @media (max-width: 1200px){
            width :100%;
            text-align: center;
            margin-top: 15px;
        }
    }
  }
  .group-list {
    padding-top: 15px;
    text-align: center;
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
    
    @media (max-width: 1200px){
        flex-wrap: wrap;
    }
      a {
          flex: 1 0 0;
          @media (max-width: 1200px){
            flex: 50% 0 0;
            margin-top: 20px;
          }
          img {
              height: 50px;
              width: auto;
          }
      }
  }