.benifits {
    padding: 150px 0;
    background-image: url(../images/backgrounds/faded-workflow.jpg);
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    &-title {
        text-align: center;
    }
    &-blocks {
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        display: flex;
        margin-top: 20px;
        @media (max-width: 1200px) {
            flex-direction: column;
        }
        &-item {
            width: 33%;
            text-align: center;
            @media (max-width: 1200px) {
                width: 100%;
            }
            &-inner {
                background: #fff;
                margin: 20px;
                padding: 50px 40px;
            }
            &-icon {
                
                img {
                    max-width: 80px;
                }
            }
            &-title {
                margin-top: 30px;
                h3 {
                    text-transform: uppercase;
                }
            }
            &-content {
                margin-top: 30px;
            }
        }
    }
}