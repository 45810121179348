.btn {
  position: relative;
    font-family: inherit;
      font-size: .875rem;
      font-weight: 700;
      font-weight: 500;
      cursor: pointer;
      display: inline-block;
      line-height: 1.125rem;
      padding: 1rem 1rem;
      margin: 0;
      height: auto;
      border: 1px solid transparent;
      vertical-align: middle;
      -webkit-appearance: none;
      color: inherit;
      background-color: transparent;
  
  }
  .btn-outline {
      border-color: currentcolor;
      display: inline-block;
      width: fit-content;
      margin-top: 20px;
      line-height: 1;
      padding: 24px 35px;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 400;
      transition-property: color;
      transition-duration: 0.3s;
  }
.hvr-sweep-to-left:hover:before, .hvr-sweep-to-left:focus:before, .hvr-sweep-to-left:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}
.hvr-sweep-to-left:hover {
  color: #fff;
}

.hvr-sweep-to-left:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:#222;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.hvr-sweep-to-left-dark:hover:before, .hvr-sweep-to-left-dark:focus:before, .hvr-sweep-to-left-dark:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.hvr-sweep-to-left-dark:hover {
  color: #222!important;
}

.hvr-sweep-to-left-dark:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:#fff;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.site-footer .hvr-sweep-to-left:before {
  background: #eee;;
}
.site-footer .hvr-sweep-to-left:hover {
  color: #222;
}



.arrow-hover {
  position: relative;
  will-change: transform;
}
.arrow-hover:before {
  content: "";
  width: 25px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  right: -40px;
  transition: all 0.2s ease;
}

.arrow-hover:hover:before {
  right: -45px;
  width: 35px;
}

.arrow-hover:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: -45px;
  transition: all 0.2s ease;
}

.arrow-hover:hover:after {
  right: -50px;
}

.arrow-hover-dark {
  &:before {
    background: #fff;
  }
  &:after {
    border-color: transparent transparent transparent #222;
  }
}

.link--arrowed {
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: #222;
}
.link--arrowed .arrow-icon {
  position: relative;
  top: -1px;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  vertical-align: middle;
}
.link--arrowed .arrow-icon--circle {
  -webkit-transition: stroke-dashoffset .3s ease;
  transition: stroke-dashoffset .3s ease;
  stroke-dasharray: 95;
  stroke-dashoffset: 95;
}
.link--arrowed:hover .arrow-icon {
  -webkit-transform: translate3d(5px, 0, 0);
          transform: translate3d(5px, 0, 0);
}
.link--arrowed:hover .arrow-icon--circle {
  stroke-dashoffset: 0;
}

.learn-more-white a {
  color: #fff;
}

.large-button-text {
  font-size: 28px!important;
  padding: 20px 25px!important;
}