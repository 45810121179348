@charset "utf-8";

$brand-color:#c4912c !default;

.fleet-image-banner {
    background-image: url('../images/backgrounds/fleet-bg.jpg');
    background-size: cover;
    position: relative;
    h2 {
        color: #fff;
        .outline-text {
            -webkit-text-stroke-color: white;
        }
    }
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        top: 0;
        left: 0;
    }
}

@media (max-width: 1200px) {
    .hero {
        padding: 150px 0 50px 0!important;    
    }
    .hero:after {
       
        background: rgba(20, 20, 20, 0.38)!important;
    }
}

.contact-addressses {
    padding: 100px 0;
}

.team-section:after {
   
    background-image: url(../images/hydrovac/hurricane-hydrovac-h.svg)!important;
    background-size: 75% 75%!important;
    background-attachment: fixed;
}