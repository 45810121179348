
header {
  @media (max-width: 1200px){
      // height: 145px;
      background: #fff;
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
        background: #222;
      }
  }
  .top-bar {
    padding: 10px 0;
    width: 100%;
    background: #111;
    text-align: right;
    overflow: hidden;
    &-select {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      
    }
    .selectParent {
      width: 160px;
      
      position: relative;
      &:before {
        width: 30px;
        height: 30px;
        content: "";
        top: -3px;
        z-index: 2;
        position: absolute;
        left: -34px;
        background-position: center;
        background: url(../images/logos/hurricane-h-tiny.png);
        background-size: contain;
      }
    }
    option {
      background-color: #111;
      color: #fff;
    }
    .selectParent select {
      text-indent: 1px;
      text-overflow: '';
      width: 140px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1;
      font-weight: 800;
      padding: 2px 2px 2px 2px;
      margin-right: 10px;
      border: none;
      color: #fff;
      background: #111;
      background-color: #111;
      position: relative;
    }
    .selectParent:after {
      content: "";
      width: 26px;
      height: 30px;
      content: "";
      top: -1px;
      z-index: 2;
      position: absolute;
      background: transparent url(../images/down-icon.svg) no-repeat center center;
      background-size: contain;
      right: -5px;
    }
  }
  .header-container {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0 80px;
    top: 60px;
    z-index: 1;
    z-index: 888;
    @media (max-width: 1600px){
      padding: 0 40px;
    }
    @media (max-width: 1200px){
      padding: 0 10px;
    }
    .header-logo {
      position: relative;
      z-index: 777;
      img {
        width: 250px;
        height: auto;
      }
    }
  }
  .header-navigation {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 999;

  }
}
.navigation {
  z-index: 888;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  &-close {
    padding: 40px 20px;
  }
  &-logo {
    margin-bottom: 40px;
    
  }
  &-contact {
    margin-top: 40px;
    color: #ddd;
    line-height: 2;
    position: relative;
    z-index: 1;
    font-size: 18px;
    font-weight: 600;
    a {
      color: #ddd;
      font-weight: 600;
      text-decoration: none;
    }
  }
  &-list {
    a {
      font-size: 44px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 800;
      text-decoration: none;
    }
  }
  &-items {
      text-align: center;
      height: 100%;
      display: flex;
      width: 100%;
      text-align: center;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  }
}

.headroom {
will-change: transform;
transition: transform 200ms linear;

}
.headroom--pinned {
transform: translateY(0%);
}
.headroom--unpinned {
transform: translateY(-100%);
}
.headroom--not-top {
position: fixed!important;
  top: 0!important;
  background: #fff;
  padding-top: 20px!important;
  padding-bottom: 20px!important;
  z-index: 555!important;
  box-shadow: 0 0 12px rgba(0,0,0,0.3);
  margin-top: 0!important;
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    background-color: #111;
  }
  .header-navigation {
    position: absolute;
    top: 0;
    height: 100%;
    right: 20px;
    z-index: 999;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .full-header-nav ul li,
  .full-header-nav ul li a {
    color: #222;
  }
  .full-header-nav-button {
    border: 1px solid #222;
    color: #fff;
  }
  ul li ul li a {
    color: #fff!important;
  }
  .underline-hover:after {
    background: #222;
  }
}


.full-header {
width: 100%;
position: absolute;
z-index: 999;

margin-top: 20px;
.mobile-sub-nav {
  a {
    font-size: 24px;
    color: #aaa;
  }
}
@media (max-width: 1200px){
  margin-top: 0;
  padding: 15px 0;
}
&-nav-button {
  border: 1px solid #222;
  color: #fff;
  font-size: 20px;
  background:$brand-color;
}
.header-mobile-book-now {
  position: absolute;
  right: 93px;
  border: 2px solid #222;
  line-height: 1;
  padding: 0px;
  display: none;
  top: 12px;
  
  .full-header-nav-button {
    padding: 5px 18px;
    text-decoration: none;
    
    color: #fff;
    font-weight: 600;
    position: relative;
    font-size: 14px;
    span {
      color: #222;
    }
  }
  @media (max-width: 1200px){
    display: block;
  }
}
&-container {
  display: flex;
  padding: 0 20px;
  width: 100%;
  .mobile {
    display: none;
    @media (max-width: 1200px){
      display: block;
      margin-top: 10px;
    }
  }
}
&-logo {
  width: 30%;
  margin-left: 20px;
  display: inline-flex;
  align-items: center;
  @media (max-width: 1200px){
    margin-left: 0;
  }
  img {
    width: 100%;
    height: auto;
    max-width: 250px;
  }
}
.navigation {
  height: 100vh;
}
&-nav {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 1200px){
    display: none;
  }
  .hvr-sweep-to-left:before {
    background: $brand-color;
  }
  .hvr-sweep-to-left:hover {
    color: #fff;
  }
  &-button {
    text-align: center;
    line-height: 1;
    border: 1px solid #fff;
    display: block;
    padding: 15px;  
    position: relative;
    font-weight: 800;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    span {
      display: block;
      
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      display: inline-block;
      position: relative;
      padding: 20px;
      text-transform: uppercase;
      font-weight: 800;
      text-decoration: none;
      color: #fff;
     

      .nolink {
        cursor: pointer;
        padding-bottom: 4px;
      }
      a {
        text-transform: uppercase;
        font-weight: 800;
        text-decoration: none;
        color: #fff;
        padding-bottom: 4px;
      }
    }
    
  }
}
ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: -25%;
  display: none;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  width: 100%;
  background: #222;
  text-align: center;
  padding: 12px;
  transition: all ease-in-out 0.5s;
  a {
    line-height: 1.2;
  }
}
ul li ul li.active {
  background: $brand-color;
}
ul li ul li:hover {
  background: #555;
}
}


.top-notice {
  background: #222;
  padding: 6px 0;
  @media (max-width: 1200px){
    display: none;
  }
  &-content {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    a {
      color: $brand-color;
      padding: 5px;
      font-size: 20px;
      // border: 1px solid #fff;;
      margin: 2px;
      display: inline-block;
      text-decoration: none;
      position: relative;
      z-index: 0;
    }
  }
  }