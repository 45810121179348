.hex-grid {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 25%;
    line-height: 1;
    padding: 0 0 30px 0;
    @media (max-width: 1200px){
        padding: 0 0 30px 0;
        // margin-top: 15%;
        width: 100%;
        margin-left:  auto;
        margin-right:  auto;
        max-width: 900px;
    }
    &-item {
        flex: 0 0 50%;
        padding-left: 4px;
        // height: 100%;
        margin-bottom: -6%;
        filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.65));
        &-inner {
            display: inline-block;
            position: relative;
            clip-path:  url(#clip-svg-demo);
            -webkit-clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
            clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
            
        }
        &:nth-child(odd) {
            margin-top: -25%;   
            @media (max-width: 1200px){
                margin-left: 4%;
            }
        }
        &:nth-child(even) {
            margin-top: -2%;
            margin-bottom: -1%;
            margin-left: -9%;   
        }
        
    }
    &-img {
        width: 100%;
        height: auto;
    }
    &-title {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        padding: 0 20px;
        h2 {
            font-weight: 800;   
            font-size: 30px;
            @media (max-width: 1200px) {
                font-size: 16px;
            }
        }
    }
    &-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        /* z-index: 3; */
        border: 0;
        transition: all 0.5s ease;
        visibility: hidden;
        opacity: 0;
    }
    &-item:hover {
        .hex-grid-hover {
            visibility: visible;
            opacity: 1;
        }
    }
}


// .hex-grid-home {
//     .hex-grid-item:hover {
        
//     }
// }